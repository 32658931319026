<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    :class="bgColorClass"
  >
    <v-row
      v-if="!videos.length"
    >
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            動画がありません。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" sm="6" md="4"
        v-for="video in sortedVideos"
        :key="video.cast_id + video.video_id"
      >
        <v-card class="my-2 pa-2 secondary">
          <h3 class="mb-1">{{ video.video_name }}</h3>
          <video
            controls
            :poster="video.poster_url"
            :src="video.video_url"
          ></video>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      casts: [],
      videos: [],
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    bgColorClass() {
      if (this.setting.site_bg_image_url) {
        return ''
      }
      else if (this.setting.is_dark_theme) {
        return 'secondary darken-1'
      }
      else {
        return 'secondary lighten-1'
      }
    },
    sortedVideos() {
      const sorted = this.videos

      return sorted.sort(function(a,b){
         if (a.created_at > b.created_at ) return -1
         if (b.created_at > a.created_at ) return 1
         return 0
      })
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.getVideos()
    .catch( error => { AlertApiError(error) })
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    async getVideos() {
      await this.getCasts()

      await Promise.all([
        this.setSiteVideos(),
        this.setCastVideos(),
      ])
    },

    getCasts() {
      return this.apiTool.getRequest('cast/').then( results => {
        if (!results || !results.length) return
        this.casts = results
      })
    },

    setSiteVideos() {
      return this.apiTool.getRequest('common/site-video/').then( results => {
        if (!results || !results.length) return

        results.map( video => {
          video.cast_id = 0
          this.videos.push(video)
        })
      })
    },

    setCastVideos() {
      return this.apiTool.getRequest('common/cast-video/').then( results => {
        if (!results || !results.length) return

        results.map( castV => {
          const target = this.casts.find( cast => cast.cast_id === castV.cast_id )
          castV.video_name = target !== undefined ? target.name : ''
          this.videos.push(castV)
        })
      })
    },
  }
}
</script>

<style scoped>
video {
  max-width: 100%;
  max-height: 500px;
}
</style>
